const asanas = [
  {"sanskrit_name": "Adho Mukha Svanasana", "english_translation": "Downward Facing Dog Pose"},
  {"sanskrit_name": "Tadasana", "english_translation": "Mountain Pose"},
  {"sanskrit_name": "Uttanasana", "english_translation": "Standing Forward Bend"},
  {"sanskrit_name": "Utkatasana", "english_translation": "Chair Pose"},
  {"sanskrit_name": "Virabhadrasana I", "english_translation": "Warrior I Pose"},
  {"sanskrit_name": "Virabhadrasana II", "english_translation": "Warrior II Pose"},
  {"sanskrit_name": "Virabhadrasana III", "english_translation": "Warrior III Pose"},
  {"sanskrit_name": "Trikonasana", "english_translation": "Triangle Pose"},
  {"sanskrit_name": "Parsvakonasana", "english_translation": "Extended Side Angle Pose"},
  {"sanskrit_name": "Ardha Chandrasana", "english_translation": "Half Moon Pose"},
  {"sanskrit_name": "Parivrtta Trikonasana", "english_translation": "Revolved Triangle Pose"},
  {"sanskrit_name": "Parivrtta Parsvakonasana", "english_translation": "Revolved Side Angle Pose"},
  {"sanskrit_name": "Prasarita Padottanasana", "english_translation": "Wide-Legged Forward Bend"},
  {"sanskrit_name": "Padangusthasana", "english_translation": "Big Toe Pose"},
  {"sanskrit_name": "Padahastasana", "english_translation": "Hand Under Foot Pose"},
  {"sanskrit_name": "Garudasana", "english_translation": "Eagle Pose"},
  {"sanskrit_name": "Natarajasana", "english_translation": "Dancer Pose"},
  {"sanskrit_name": "Vrikshasana", "english_translation": "Tree Pose"},
  {"sanskrit_name": "Uttana Shishosana", "english_translation": "Extended Puppy Pose"},
  {"sanskrit_name": "Bakasana", "english_translation": "Crow Pose"},
  {"sanskrit_name": "Kakasana", "english_translation": "Crow Pose"},
  {"sanskrit_name": "Urdhva Mukha Svanasana", "english_translation": "Upward Facing Dog Pose"},
  {"sanskrit_name": "Bhujangasana", "english_translation": "Cobra Pose"},
  {"sanskrit_name": "Salabhasana", "english_translation": "Locust Pose"},
  {"sanskrit_name": "Dhanurasana", "english_translation": "Bow Pose"},
  {"sanskrit_name": "Setu Bandhasana", "english_translation": "Bridge Pose"},
  {"sanskrit_name": "Urdhva Dhanurasana", "english_translation": "Upward Bow Pose"},
  {"sanskrit_name": "Salamba Sarvangasana", "english_translation": "Supported Shoulderstand"},
  {"sanskrit_name": "Halasana", "english_translation": "Plow Pose"},
  {"sanskrit_name": "Karnapidasana", "english_translation": "Ear Pressure Pose"},
  {"sanskrit_name": "Pincha Mayurasana", "english_translation": "Feathered Peacock Pose"},
  {"sanskrit_name": "Adho Mukha Vrksasana", "english_translation": "Handstand"},
  {"sanskrit_name": "Sirsasana", "english_translation": "Headstand"},
  {"sanskrit_name": "Salamba Sirsasana", "english_translation": "Supported Headstand"},
  {"sanskrit_name": "Vasisthasana", "english_translation": "Side Plank Pose"},
  {"sanskrit_name": "Chaturanga Dandasana", "english_translation": "Four-Limbed Staff Pose"},
  {"sanskrit_name": "Paripurna Navasana", "english_translation": "Boat Pose"},
  {"sanskrit_name": "Ardha Navasana", "english_translation": "Half Boat Pose"},
  {"sanskrit_name": "Jathara Parivartanasana", "english_translation": "Revolved Abdomen Pose"},
  {"sanskrit_name": "Eka Pada Rajakapotasana", "english_translation": "One-Legged King Pigeon Pose"},
  {"sanskrit_name": "Kapotasana", "english_translation": "Pigeon Pose"},
  {"sanskrit_name": "Supta Baddha Konasana", "english_translation": "Reclining Bound Angle Pose"},
  {"sanskrit_name": "Baddha Konasana", "english_translation": "Bound Angle Pose"},
  {"sanskrit_name": "Upavistha Konasana", "english_translation": "Wide-Angle Seated Forward Bend"},
  {"sanskrit_name": "Supta Padangusthasana", "english_translation": "Reclining Hand-to-Big-Toe Pose"},
  {"sanskrit_name": "Ananda Balasana", "english_translation": "Happy Baby Pose"},
  {"sanskrit_name": "Pavanamuktasana", "english_translation": "Wind-Relieving Pose"},
  {"sanskrit_name": "Paschimottanasana", "english_translation": "Seated Forward Bend"},
  {"sanskrit_name": "Purvottanasana", "english_translation": "Upward Plank Pose"},
  {"sanskrit_name": "Janu Sirsasana", "english_translation": "Head-to-Knee Pose"},
  {"sanskrit_name": "Marichyasana I", "english_translation": "Marichi's Pose I"},
  {"sanskrit_name": "Marichyasana II", "english_translation": "Marichi's Pose II"},
  {"sanskrit_name": "Marichyasana III", "english_translation": "Marichi's Pose III"},
  {"sanskrit_name": "Marichyasana IV", "english_translation": "Marichi's Pose IV"},
  {"sanskrit_name": "Ardha Matsyendrasana", "english_translation": "Half Lord of the Fishes Pose"},
  {"sanskrit_name": "Parivrtta Janu Sirsasana", "english_translation": "Revolved Head-to-Knee Pose"},
  {"sanskrit_name": "Gomukhasana", "english_translation": "Cow Face Pose"},
  {"sanskrit_name": "Virasana", "english_translation": "Hero Pose"},
  {"sanskrit_name": "Supta Virasana", "english_translation": "Reclining Hero Pose"},
  {"sanskrit_name": "Sukhasana", "english_translation": "Easy Pose"},
  {"sanskrit_name": "Padmasana", "english_translation": "Lotus Pose"},
  {"sanskrit_name": "Siddhasana", "english_translation": "Accomplished Pose"},
  {"sanskrit_name": "Bakasana", "english_translation": "Crane Pose"},
  {"sanskrit_name": "Parighasana", "english_translation": "Gate Pose"},
  {"sanskrit_name": "Malasana", "english_translation": "Garland Pose"},
  {"sanskrit_name": "Savasana", "english_translation": "Corpse Pose"},
  {"sanskrit_name": "Balasana", "english_translation": "Child's Pose"},
  {"sanskrit_name": "Anjaneyasana", "english_translation": "Low Lunge"},
  {"sanskrit_name": "Utthita Hasta Padangusthasana", "english_translation": "Extended Hand-to-Big-Toe Pose"},
  {"sanskrit_name": "Utthita Parsvakonasana", "english_translation": "Extended Side Angle Pose"},
  {"sanskrit_name": "Utthita Trikonasana", "english_translation": "Extended Triangle Pose"},
  {"sanskrit_name": "Virabhadrasana I", "english_translation": "Warrior I Pose"},
  {"sanskrit_name": "Virabhadrasana II", "english_translation": "Warrior II Pose"},
  {"sanskrit_name": "Virabhadrasana III", "english_translation": "Warrior III Pose"},
  {"sanskrit_name": "Viparita Karani", "english_translation": "Legs-Up-the-Wall Pose"},
  {"sanskrit_name": "Ardha Pincha Mayurasana", "english_translation": "Dolphin Pose"},
  {"sanskrit_name": "Ardha Uttanasana", "english_translation": "Half Standing Forward Bend"},
  {"sanskrit_name": "Ashwa Sanchalanasana", "english_translation": "Equestrian Pose"},
  {"sanskrit_name": "Bhekasana", "english_translation": "Frog Pose"},
  {"sanskrit_name": "Bitilasana", "english_translation": "Cow Pose"},
  {"sanskrit_name": "Chakrasana", "english_translation": "Wheel Pose"},
  {"sanskrit_name": "Chaturanga Dandasana", "english_translation": "Four-Limbed Staff Pose"},
  {"sanskrit_name": "Dandasana", "english_translation": "Staff Pose"},
  {"sanskrit_name": "Durvasasana", "english_translation": "Pose Dedicated to Sage Durvasa"},
  {"sanskrit_name": "Hanumanasana", "english_translation": "Monkey Pose"},
  {"sanskrit_name": "Krounchasana", "english_translation": "Heron Pose"},
{"sanskrit_name": "Kurmasana", "english_translation": "Tortoise Pose"},
{"sanskrit_name": "Mandukasana", "english_translation": "Frog Pose"},
{"sanskrit_name": "Mayurasana", "english_translation": "Peacock Pose"},
{"sanskrit_name": "Mukta Hasta Sirsasana", "english_translation": "Free Hands Headstand"},
{"sanskrit_name": "Parivrtta Ardha Chandrasana", "english_translation": "Revolved Half Moon Pose"},
{"sanskrit_name": "Parivrtta Surya Yantrasana", "english_translation": "Compass Pose"},
{"sanskrit_name": "Parivrtta Utkatasana", "english_translation": "Revolved Chair Pose"},
{"sanskrit_name": "Purvottanasana", "english_translation": "Upward Plank Pose"},
{"sanskrit_name": "Samakonasana", "english_translation": "Straight Angle Pose"},
{"sanskrit_name": "Sarvangasana", "english_translation": "Shoulderstand Pose"},
{"sanskrit_name": "Shalabhasana", "english_translation": "Locust Pose"},
{"sanskrit_name": "Simhasana", "english_translation": "Lion Pose"},
{"sanskrit_name": "Supta Kurmasana", "english_translation": "Sleeping Tortoise Pose"},
{"sanskrit_name": "Supta Virasana", "english_translation": "Reclined Hero Pose"},
{"sanskrit_name": "Tittibhasana", "english_translation": "Firefly Pose"},
{"sanskrit_name": "Upward Bow Pose", "english_translation": "Wheel Pose"},
{"sanskrit_name": "Urdhva Prasarita Eka Padasana", "english_translation": "Standing Split"},
{"sanskrit_name": "Utthita Ashwa Sanchalanasana", "english_translation": "High Lunge"},
{"sanskrit_name": "Utkata Konasana", "english_translation": "Goddess Pose"},
{"sanskrit_name": "Vajrasana", "english_translation": "Thunderbolt Pose"},
{"sanskrit_name": "Vishvamitrasana", "english_translation": "Pose Dedicated to Sage Vishvamitra"},
{"sanskrit_name": "Vrikshasana", "english_translation": "Tree Pose"},
{"sanskrit_name": "Yoganidrasana", "english_translation": "Yogic Sleep Pose"},
{"sanskrit_name": "Ardha Bhekasana", "english_translation": "Half Frog Pose"},
{"sanskrit_name": "Ardha Chandrasana", "english_translation": "Half Moon Pose"},
{"sanskrit_name": "Ardha Matsyendrasana", "english_translation": "Half Lord of the Fishes Pose"},
{"sanskrit_name": "Ardha Padmasana", "english_translation": "Half Lotus Pose"},
{"sanskrit_name": "Ardha Uttanasana", "english_translation": "Half Forward Bend"},
{"sanskrit_name": "Astavakrasana", "english_translation": "Eight-Angle Pose"},
{"sanskrit_name": "Baddha Hasta Sirsasana", "english_translation": "Bound Hands Headstand"},
{"sanskrit_name": "Baddha Konasana", "english_translation": "Bound Angle Pose"},
{"sanskrit_name": "Bharadvajasana I", "english_translation": "Pose Dedicated to Sage Bharadvaja"},
{"sanskrit_name": "Bhujapidasana", "english_translation": "Shoulder Pressing Pose"},
{"sanskrit_name": "Chapasana", "english_translation": "Bow Pose"},
{"sanskrit_name": "Dandayamana Bharmanasana", "english_translation": "Balancing Table Pose"},
{"sanskrit_name": "Eka Pada Bakasana", "english_translation": "One-Legged Crane Pose"},
{"sanskrit_name": "Eka Pada Koundinyasana I", "english_translation": "Pose Dedicated to Sage Koundinya I"},
{"sanskrit_name": "Eka Pada Koundinyasana II", "english_translation": "Pose Dedicated to Sage Koundinya II"},
{"sanskrit_name": "Eka Pada Rajakapotasana I", "english_translation": "One-Legged King Pigeon Pose I"},
{"sanskrit_name": "Eka Pada Rajakapotasana II", "english_translation": "One-Legged King Pigeon Pose II"},
{"sanskrit_name": "Eka Pada Sirsasana", "english_translation": "One-Legged Headstand"},
{"sanskrit_name": "Eka Pada Viparita Dandasana", "english_translation": "One-Legged Inverted Staff Pose"},
{"sanskrit_name": "Garbha Pindasana", "english_translation": "Embryo Pose"},
{"sanskrit_name": "Halasana", "english_translation": "Plow Pose"},
{"sanskrit_name": "Kapinjalasana", "english_translation": "Pose Dedicated to Sage Kapinjala"},
{"sanskrit_name": "Kasyapasana", "english_translation": "Pose Dedicated to Sage Kasyapa"},
{"sanskrit_name": "Kurmasana", "english_translation": "Tortoise Pose"},
{"sanskrit_name": "Malasana", "english_translation": "Garland Pose"},
{"sanskrit_name": "Matsyasana", "english_translation": "Fish Pose"},
{"sanskrit_name": "Mayurasana", "english_translation": "Peacock Pose"},
{"sanskrit_name": "Mukta Hasta Sirsasana", "english_translation": "Free Hands Headstand"},
{"sanskrit_name": "Parivrtta Ardha Chandrasana", "english_translation": "Revolved Half Moon Pose"},
{"sanskrit_name": "Parivrtta Parsvakonasana", "english_translation": "Revolved Side Angle Pose"},
{"sanskrit_name": "Parivrtta Surya Yantrasana", "english_translation": "Compass Pose"},
{"sanskrit_name": "Parivrtta Trikonasana", "english_translation": "Revolved Triangle Pose"},
{"sanskrit_name": "Parivrtta Utkatasana", "english_translation": "Revolved Chair Pose"},
{"sanskrit_name": "Pasasana", "english_translation": "Noose Pose"},
{"sanskrit_name": "Phalakasana", "english_translation": "Plank Pose"},
{"sanskrit_name": "Samakonasana", "english_translation": "Straight Angle Pose"},
{"sanskrit_name": "Sarvangasana", "english_translation": "Shoulderstand Pose"},
{"sanskrit_name": "Shalabhasana", "english_translation": "Locust Pose"},
{"sanskrit_name": "Simhasana", "english_translation": "Lion Pose"},
{"sanskrit_name": "Supta Kurmasana", "english_translation": "Sleeping Tortoise Pose"},
{"sanskrit_name": "Supta Padangusthasana", "english_translation": "Reclining Hand-to-Big-Toe Pose"},
{"sanskrit_name": "Supta Virasana", "english_translation": "Reclined Hero Pose"},
{"sanskrit_name": "Tittibhasana", "english_translation": "Firefly Pose"},
{"sanskrit_name": "Upavistha Konasana", "english_translation": "Wide-Angle Seated Forward Bend"},
{"sanskrit_name": "Urdhva Mukha Paschimottanasana", "english_translation": "Upward Facing Intense Stretch Pose"},
{"sanskrit_name": "Urdhva Mukha Svanasana", "english_translation": "Upward Facing Dog Pose"},
{"sanskrit_name": "Urdhva Padmasana", "english_translation": "Upward Lotus Pose"},
{"sanskrit_name": "Urdhva Prasarita Eka Padasana", "english_translation": "Standing Split"},
{"sanskrit_name": "Utthita Hasta Padangusthasana", "english_translation": "Extended Hand-to-Big-Toe Pose"},
{"sanskrit_name": "Utthita Parsvakonasana", "english_translation": "Extended Side Angle Pose"},
{"sanskrit_name": "Utthita Trikonasana", "english_translation": "Extended Triangle Pose"},
{"sanskrit_name": "Viparita Karani", "english_translation": "Legs-Up-the-Wall Pose"},
{"sanskrit_name": "Vrikshasana", "english_translation": "Tree Pose"},
{"sanskrit_name": "Yoganidrasana", "english_translation": "Yogic Sleep Pose"}
];

const listOfAsanasInSanskrit = asanas.map(asana => asana.sanskrit_name);

export {
  asanas,
  listOfAsanasInSanskrit
};