import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { getUserProfile, updateUserProfile } from "../api";
import Footer from "../components/footer"

import { PracticeCreator } from "../components/practiceCreator";

const listOfSports = ["Running", "Cycling", "Swimming", "Weightlifting", "Golf", "Football", "Tennis"] as const;
const YogaExperienceOptions = ["Beginner", "Intermediate", "Advanced"] as const;
type YogaExperience = typeof YogaExperienceOptions[number];

const Dashboard = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  useEffect(() => {
    console.log("Loading:", isLoading);
    console.log("Authenticated:", isAuthenticated);
    if (!isLoading && !isAuthenticated) {
      // Save the current location before redirecting
      const currentLocation = window.location.pathname;
      loginWithRedirect({
        appState: { returnTo: currentLocation },
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);


  const [userProfile, setUserProfile] = useState<any>(null);
  const [tab, setTab] = useState("Activities" as "Profile" | "Activities");
  const [error, setError] = useState("");
  const [practiceCreatorOpen, setPracticeCreatorOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    given_name: "",
    age: null as number | null,
    weight: null as number | null,
    height: null as number | null,
    yoga_experience: "Beginner" as YogaExperience,
    sports: [] as string[],
    focus: "",
    linked_strava: false,
    recentActivities: [] as any[],
  });

  useEffect(() => {
    const fetchUserData = async (user: any) => {
      const userFetch = await getUserProfile(user);
      console.log(userFetch);
      setUserProfile(userFetch.userData);
      !userFetch.userData.height && setTab("Profile");
      setUserDetails({
        given_name: userFetch.userData.given_name,
        age: userFetch.userData.age,
        weight: userFetch.userData.weight,
        height: userFetch.userData.height,
        yoga_experience: userFetch.userData.yoga_experience,
        sports: userFetch.userData.sports,
        focus: userFetch.userData.focus,
        linked_strava: userFetch.userData.linked_strava,
        recentActivities: userFetch.userData.activities ? userFetch.userData.activities.map(activityFormatter) : []
      });
    }

    //console.log(user)
    user && fetchUserData(user);
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const tabElement = (tabLabel: string) => {
    return (
      <div 
        className={`${tabLabel === tab ? "border-bfy-green text-bfy-green" : "border-[#333] text-white hover:border-white"} border-b-4 pb-1 px-2 cursor-pointer`}
        onClick={() => setTab(tabLabel as any)}
      >
        <h2 className="text-lg font-extrabold">
          {tabLabel === "Profile" && !userProfile.height && <><i className="fas fa-exclamation-circle text-lg"></i>&nbsp;&nbsp;</>}
          {tabLabel}
        </h2>
      </div>
    )
  }

  const updateProfile = async () => {
    try {
      if (!user || !user.sub) return;
      const updatedProfile = await updateUserProfile(user.sub, userDetails);
      console.log(updatedProfile);
      setError("");
    } catch (error) {
      setError("There was a problem updating your profile.");
    }
  }

  const activityFormatter = (activity: any) => {
    let icon = <></>;

    if (activity.type) {
      switch (activity.type) {
        case "VirtualRide":
        case "Ride":
          icon = <i className="fas fa-biking"></i>;
          break;
        case "Run":
          icon = <i className="fas fa-running"></i>;
          break;
        case "Swim":
          icon = <i className="fas fa-swimmer"></i>;
          break;
        case "Walk":
          icon = <i className="fas fa-walking"></i>;
          break;
        case "Yoga":
          icon = <img src="/activity-type-yoga.png" alt="Yoga" className="h-10" />;
          break;
        default:
          icon = <i className="fas fa-dumbbell"></i>;
          break;
      }
    }
    else {
      icon = <img src="/yoga-icon.png" alt="Yoga" className="h-8" />;
    }

    return {
      icon,
      id: activity.id,
      title: activity.name,
      timeSince: formatTimeSinceActivity(activity.start_date),
      heartrate: activity.average_heartrate ? activity.average_heartrate : "N/A",
      distance: activity.distance ? formatActivityDistance(activity.distance) : "N/A",
      duration: activity.moving_time ? formatActivityTime(activity.moving_time) : "N/A",
      calories: activity.kilojoules ? activity.kilojoules : "N/A",
      exertion: activity.suffer_score ? `${activity.suffer_score}%` : "N/A",
    }
  }

  const formatActivityTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours > 0 ? hours + "h " : ""}${minutes > 0 ? minutes + "m " : ""}${seconds > 0 ? seconds + "s" : ""}`;
  }

  const formatActivityDistance = (distance: number) => {
    return distance > 1000 ? `${(distance / 1000).toFixed(2)} km` : `${distance} m`;
  }

  const formatTimeSinceActivity = (time: number) => {
    const currentTime = new Date().getTime();
    const activityTime = new Date(time).getTime();
    const timeSince = (currentTime - activityTime) / 1000;

    if (timeSince < 60) {
      return `${Math.floor(timeSince)}s ago`;
    }
    else if (timeSince < 3600) {
      return `${Math.floor(timeSince / 60)}m ago`;
    }
    else if (timeSince < 86400) {
      return `${Math.floor(timeSince / 3600)}h ago`;
    }
    else {
      return `${Math.floor(timeSince / 86400)}d ago`;
    }
  }

  return (
    <div className="min-h-screen">
      {practiceCreatorOpen && <PracticeCreator userDetails={userDetails} />}

      {userProfile && !practiceCreatorOpen && (
        <div className="h-screen">
          <div className="bg-off-black">
            <div className="container pt-12 mx-auto px-2">
              <div className="flex gap-6">
                <img src={userProfile.picture} alt="Me." className="h-32 w-32 object-cover rounded-2xl boxShadow" />

                <div className="flex flex-col gap-4 mt-auto pb-2 text-white">
                  <h1 className="text-3xl font-semibold"><span className="text-4xl font-extrabold">{userProfile.given_name}</span>&nbsp;{userProfile.family_name}</h1>
                </div>

                <div className="flex gap-4 ml-auto mt-auto">
                  <button 
                    className="btn btn-primary text-white font-bold text-xl px-4 py-2 rounded"
                    onClick={() => setPracticeCreatorOpen(true)}
                  >
                    Generate New Practice
                  </button>


                  {userProfile.linked_strava 
                    ? <button 
                        className="strava-button text-white font-bold text-xl px-4 py-2 rounded"
                      ><i className="fa-sharp fa-solid fa-circle-check"></i>&nbsp;&nbsp;Strava Connected</button>
                    : <a href="https://www.strava.com/oauth/authorize?client_id=129678&response_type=code&redirect_uri=http://localhost:3000/exchangeToken&approval_prompt=force&scope=read,activity:read,activity:write">
                        <button 
                          className="strava-button text-white font-bold text-xl px-4 py-2 rounded"
                        >Connect Strava</button>
                      </a>
                  }
                </div>
              </div>

              <div className="flex gap-4 mt-8">
                {["Activities", "Profile"].map(tabElement)}
              </div>
            </div>
          </div>

          <div className="container mx-auto py-8">
            {tab === "Activities" && (
              <div className="flex flex-col gap-4">
                {
                  userDetails.recentActivities.length === 0
                  ? <div className="flex flex-col items-center justify-center gap-4 min-h-[40vh]">
                      <i className="fa-solid fa-person-running-fast text-4xl md:text-6xl"></i>
                      <h3 className="font-semibold text-lg">You don't have any activities yet.</h3>
                      <button 
                        className="bg-bfy-green hover:bg-black text-white font-bold text-xl px-4 py-2 rounded"
                        onClick={() => setPracticeCreatorOpen(true)}
                      >
                        Generate First Practice
                      </button>
                    </div>
                  : userDetails.recentActivities.map((activity) => (
                    <div key={activity.title} className="py-4 px-6 tile-card flex gap-4 items-center text-white">
                      <div className="w-[55px] flex justify-center text-4xl text-bfy-green">
                        {activity.icon}
                      </div>
                      <div>
                        <h2 className="text-xl font-bold">{activity.title}</h2>
                        <div className="flex gap-4">
                          {activity.heartrate !== "N/A" && <p><i className="fa-solid fa-heart-pulse"></i>&nbsp;{activity.heartrate}</p>}
                          <p><i className="fa-solid fa-map-location"></i>&nbsp;{activity.distance}</p>
                          <p><i className="fa-solid fa-stopwatch"></i>&nbsp;{activity.duration}</p>
                          {activity.calories !== "N/A" && <p><i className="fa-solid fa-fire"></i>&nbsp;{activity.calories}</p>}
                          <p><i className="fa-solid fa-battery-full"></i>&nbsp;{activity.exertion}</p>
                        </div>
                      </div>

                      <a className="ml-auto strava-button rounded-md py-2 px-4 text-white font-bold" href={`https://www.strava.com/activities/${activity.id}`} target="_blank">
                        <i className="fas fa-external-link-alt"></i>&nbsp;&nbsp;Strava
                      </a>
                    </div>
                    ))
                }
              </div>
            )}
            {tab === "Profile" && (
              <div className="grid grid-cols-2 gap-4  text-white p-4 md:p-8 tile-card">
                <div>
                  <label className="font-semibold mb-4">First Name:</label>
                  <input 
                    type="text"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    value={userDetails.given_name}
                    onChange={(e) => setUserDetails({ ...userDetails, given_name: e.target.value })}
                  />
                </div>

                <div>
                  <label className="font-semibold mb-4">Age:</label>
                  <input 
                    type="number"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    value={userDetails.age ? userDetails.age : ""}
                    onChange={(e) => setUserDetails({ ...userDetails, age: parseInt(e.target.value) })}
                  />
                </div>

                <div>
                  <label className="font-semibold mb-4">Height (cm):</label>
                  <input 
                    type="number"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    value={userDetails.height ? userDetails.height : ""}
                    onChange={(e) => setUserDetails({ ...userDetails, height: parseInt(e.target.value) })}
                  />
                </div>

                <div>
                  <label className="font-semibold mb-4">Weight (kg):</label>
                  <input 
                    type="number"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    value={userDetails.weight ? userDetails.weight : ""}
                    onChange={(e) => setUserDetails({ ...userDetails, weight: parseInt(e.target.value) })}
                  />
                </div>

                <div>
                  <label className="font-semibold mb-4">Yoga Experience:</label>
                  <div className="grid grid-cols-3 gap-2">
                    {YogaExperienceOptions.map((exp) => (
                      <button
                        key={exp}
                        type="button"
                        onClick={() => setUserDetails({ ...userDetails, yoga_experience: exp })}
                        className={`border border-gray-300 rounded px-3 py-2 ${userDetails.yoga_experience === exp ? "bg-green-600 text-white border-green-600 font-bold" : ""}`}
                      >
                        {exp}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="col-span-2">
                  <label className="font-semibold mb-4">What would you like to focus on with your Yoga:</label>
                  <textarea 
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    value={userDetails.focus}
                    onChange={(e) => setUserDetails({ ...userDetails, focus: e.target.value })}
                  />
                </div>

                <div className="col-span-2">
                  <label className="font-semibold mb-4">What sports do you do?</label>
                  <div className="flex gap-4 flex-wrap">
                    {listOfSports.map((sport) => (
                      <button
                        key={sport}
                        type="button"
                        onClick={() => setUserDetails({ ...userDetails, sports: userDetails.sports.includes(sport) ? userDetails.sports.filter((s) => s !== sport) : [...userDetails.sports, sport] })}
                        className={`border border-gray-300 rounded px-3 py-2 ${userDetails.sports.includes(sport) ? "bg-green-600 text-white border-green-600" : ""}`}
                      >
                        {sport}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="col-span-2">
                  <button 
                    className="btn btn-primary w-full"
                    onClick={() => updateProfile()}
                  >Update details</button>
                </div>

                {error && (
                  <div className="col-span-2">
                    <p className="text-red-500">{error}</p>
                  </div>
                )}
            </div>
            )}
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default Dashboard;