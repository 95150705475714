import React, { useState, useEffect, useRef } from "react";
import { generatePractice, createPractice } from "../api";
import { asanas } from "../constants";
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { useUserOrRedirectToLogin } from "../utils";

const PracticeCreator = ({ userDetails }: { userDetails: any }) => {
  const userSub = useUserOrRedirectToLogin();

  const [focusScores, setFocusScores] = useState({ Strength: 5, Flexibility: 5, Balance: 5, Relaxation: 5 });
  const [hasSetFocusScores, setHasSetFocusScores] = useState(false);

  const [energyLevel, setEnergyLevel] = useState(5);
  const [hasSetEnergyLevel, setHasSetEnergyLevel] = useState(false);

  const [practiceDuration, setPracticeDuration] = useState(null as number | null);
  const [hasSetPracticeDuration, setHasSetPracticeDuration] = useState(false);

  const circleRef = useRef(null);
  const indicatorRef = useRef(null);

  const getIconForEnergyLevel = () => {
    if (energyLevel < 2) {
      return "fa-face-sleeping";
    }
    if (energyLevel < 4) {
      return "fa-face-unamused";
    }
    if (energyLevel < 8) {
      return "fa-face-smile";
    }
    return "fa-face-laugh-beam";
  }

  const [flow, setFlow] = useState([] as { sectionOfFlow: string, duration: number, asanas: { asanaName: string, duration: number, script: string }[] }[]);
  const [summary, setSummary] = useState([] as { summarySectionTitle: string, summarySectionContent: string }[]);
  const generatePracticeForUser = async (duration: number) => {
    try {
      userDetails.energyLevel = energyLevel;
      userDetails.practiceDuration = duration;
      userDetails.practiceFocusesOutOfTen = focusScores;
      // Unset the .icon property of each recent activity
      userDetails.recentActivities.forEach((activity: any) => {
        delete activity.icon;
        delete activity.id;
      });

      const practice = await generatePractice(userDetails);

      const jsonString = practice.response.replace(/```json|```/g, '');

      // Parsing the JSON string to an object
      const parsedResponse = JSON.parse(jsonString);

      console.log("Generated practice:", parsedResponse);

      // Separating the flow and summary
      const flow = parsedResponse.flow;
      const summary = parsedResponse.summary;

      // Convert duration values into numbers (currently strings with "minute/minutes" appended)
      flow.forEach((flowSection: any) => {
        flowSection.duration = parseInt(flowSection.duration.split(" ")[0]);
        flowSection.asanas.forEach((asana: any) => {
          asana.duration = parseInt(asana.duration.split(" ")[0]);
        });
      });
      setFlow(flow);
      setSummary(summary);
    } catch (error) {
      console.error("Error generating practice:", error);
      //setError("There was an error generating the practice. Please try again.");
    }
  }

  const outputStep = (asanaObject: {asanaName: string, duration: number, script: string}, index: number, isActive: boolean) => {
    let asanaData = asanas.filter(asana => asana.sanskrit_name === asanaObject.asanaName);
    return (
      <div key={index} className={`border-b border-gray-300 p-4 ${isActive ? "bg-yellow-100" : "bg-white"} flex gap-6`}>
        <div>
          <h3 className="text-lg"><strong className="font-bold">{index + 1}. {asanaObject.asanaName}</strong> {asanaObject.duration} minute{asanaObject.duration > 1 && "s"}</h3>
          <p className="mt-2">{asanaData[0].english_translation}</p>
        </div>
        <i 
          className="ml-auto fa-solid fa-circle-minus text-xl cursor-pointer text-red-500"
          onClick={() => {
            let updatedFlow = [...flow];
            updatedFlow.forEach((section, secIndex) => {
              section.asanas = section.asanas.filter((asana, asanaIndex) => asanaIndex !== index);
            });
            setFlow(updatedFlow);
          }}
        />
        <i className="fa-solid fa-bars text-xl"/>
      </div>
    );
  }

  const handleOnDragEnd = (secIndex: number) => (result: DropResult) => {
    if (!result.destination) return;
  
    const items = Array.from(flow[secIndex].asanas);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    let updatedFlow = [...flow];
    updatedFlow[secIndex].asanas = items;
    setFlow(updatedFlow);
  };

  const startPractice = async () => {
    if (!userSub || !flow) return;
    console.log("Starting practice...");
    console.log("User sub:", userSub);

    const createResponse = await createPractice(flow, userSub);

    if (createResponse.practiceId) {
      console.log("Practice ID:", createResponse.practiceId);
      window.location.href = `/practice?pid=${createResponse.practiceId}`;
    }
    else {
      // Set error here
    }

  }

  const focusCard = (focus: string) => (
    <div className="flex flex-col gap-4 text-center tile-card text-white">
      <img src={`/practiceFocus/${focus.toLowerCase()}.png`} alt={focus} className="w-24 h-24 mx-auto"/>
      <h1 className="text-xl font-bold font-klein uppercase">{focus}</h1>
      <input
        type="range"
        className="slider"
        min={1}
        max={10}
        value={focusScores[focus as keyof typeof focusScores]}
        onChange={(e) => setFocusScores({ ...focusScores, [focus]: parseInt(e.target.value) })}
      />
    </div>
  )

  useEffect(() => {
    const circle = circleRef.current as HTMLElement | null;
    const indicator = indicatorRef.current as HTMLElement | null;
    if (!circle || !indicator) return;
    let isDragging = false;
    let currentAngle = (energyLevel - 1) * 72;

    const updateEnergyLevel = (angle: number) => {
      const newLevel = Math.round(angle / 72) + 1;
      setEnergyLevel(Math.max(1, Math.min(5, newLevel)));
    };

    const updateIndicatorPosition = (angle: number) => {
      if (indicator) {
        indicator.style.transform = `rotate(${angle}deg) translate(3.5rem)`;
      }
    };

    const handleMouseDown = () => {
      isDragging = true;
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isDragging) return;
      if (!circle) return;
      const rect = circle.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const dx = event.clientX - centerX;
      const dy = event.clientY - centerY;
      const angle = Math.atan2(dy, dx) * (180 / Math.PI) + 90;
      const adjustedAngle = angle < 0 ? 360 + angle : angle;
      currentAngle = adjustedAngle;
      updateEnergyLevel(currentAngle);
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    circle.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      circle.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [setEnergyLevel]);

  return (
    <div className="items-center justify-center w-full flex min-h-screen">
      {!hasSetFocusScores && (
        <div className="container mx-auto flex flex-col items-center gap-6 justify-center w-full">
          <h1 className="text-3xl font-bold font-klein uppercase">Let's Calibrate today's practice</h1>

          <div className="grid md:grid-cols-2 gap-8">
            {focusCard("Strength")}
            {focusCard("Flexibility")}
            {focusCard("Balance")}
            {focusCard("Relaxation")}
          </div>

          <button
            className="btn btn-primary rounded-full flex justify-between items-center py-2 pl-4 pr-3 gap-6"
            onClick={() => setHasSetFocusScores(true)}
          >
            Next
            <div className="h-8 w-8 bg-white rounded-full flex items-center justify-center">
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </button>
        </div> 
      )}

      {hasSetFocusScores && !hasSetEnergyLevel && (
        <div className="fade-in flex flex-col items-center gap-4">

          <i className={`fa-solid text-4xl md:text-8xl mb-8 ${getIconForEnergyLevel()}`}></i>

          <h1 className="text-3xl font-bold">How are you feeling today?</h1>
          <div className="flex mt-4 gap-4 items-center">
            <div className="w-[125px] text-right">
              <h3 className="text-2xl font-bold">Tired</h3>
            </div>
            <input 
              type="range"
              min={1}
              max={10}
              value={energyLevel}
              onChange={(e) => setEnergyLevel(parseInt(e.target.value) as any)}
            />
            <div className="w-[125px]">
              <h3 className="text-2xl font-bold">Energetic</h3>
            </div>
          </div>

          <button
            className="bg-white border font-bold text-xl px-4 py-2 rounded-lg w-full"
            onClick={() => setHasSetEnergyLevel(true)}
          >Next</button>
        </div>   
      )}

      {hasSetEnergyLevel && hasSetFocusScores && !hasSetPracticeDuration && (
        <div className="fade-in flex flex-col items-center gap-4">

          <i className="fa-solid fa-stopwatch text-4xl md:text-8xl mb-8"></i>

          <h1 className="text-3xl font-bold">How long do you want to practice?</h1>
          <div className="flex mt-4 border rounded-lg overflow-hidden items-center">
            <input 
              type="number"
              className="text-black font-bold text-3xl pl-6 pr-2 py-2 w-24 md:w-32 text-right border-r"
              value={practiceDuration ? practiceDuration : ""}
              onChange={(e) => {
                e.target.value ? setPracticeDuration(parseInt(e.target.value)) : setPracticeDuration(null);
              }}
            />
            <span className="text-black font-bold text-xl px-6 py-2">minutes</span>
          </div>

          <button
            disabled={!practiceDuration}
            className="bg-white border font-bold text-xl px-4 py-2 rounded-lg w-full"
            onClick={() => {
              generatePracticeForUser(practiceDuration || 0);
            }}
          >Next</button>
        </div>   
      )}

      {flow.length > 0 && (
        <div className="flex gap-4 w-full py-4 md:py-8 md:gap-8 items-start container mx-auto">
          <div className="container flex flex-col">
            {flow.map((flowSection, secIndex) => (
              <DragDropContext key={secIndex} onDragEnd={handleOnDragEnd(secIndex)}>
                <Droppable droppableId={`section-${secIndex}`}>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <div className="w-full flex bg-gray-100 border-b p-2 items-center gap-4">
                        <h2 className="text-xl font-bold">{flowSection.sectionOfFlow}</h2>
                        <p>{flowSection.duration} minute{flowSection.duration > 1 && "s"}</p>
                      </div>
                      {flowSection.asanas.map((asana, asanaIdx) => (
                        <Draggable key={asanaIdx} draggableId={`item-${asanaIdx}`} index={asanaIdx}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {outputStep(asana, asanaIdx, false)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ))}
          </div>

          <div className="flex flex-col gap-4 w-full md:max-w-[400px] sticky top-24">
            <button 
              className="bg-bfy-green border font-bold text-xl px-4 py-2 rounded-lg" 
              onClick={() => startPractice()}
            >
              Start Practice
            </button>

            <div className="bg-gray-100 border p-2 md:p-4 rounded-lg w-full md:max-w-[400px]">
              {summary.map((summarySection, index) => (
                <div key={index} className={`${index > 0 && "mt-4"}`}>
                  <h3 className="text-lg font-bold">{summarySection.summarySectionTitle}</h3>
                  <p className="mt-2">{summarySection.summarySectionContent}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
    
  );
}

export { PracticeCreator };
