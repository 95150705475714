import React, { useEffect, useState, useContext, useRef } from "react";
import { getNumberOfUsers } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Footer from "../components/footer"

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [betaPlacesRemaining, setBetaPlacesRemaining] = useState(500);

  useEffect(() => {
    const fetchBetaPlaces = async () => {
      try {
        const response = await getNumberOfUsers();
        setBetaPlacesRemaining(500 - response.count);
      } catch (error) {
        console.error("Error fetching beta places:", error);
      }
    }

    fetchBetaPlaces();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex % 5) + 1);
    }, 5000); // Change image every specified interval

    return () => clearInterval(interval);
  }, []);

  const { loginWithRedirect } = useAuth0();

  const loginWithSocial = (connection: string) => {
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
      authorizationParams: { connection },
    });
  };

  return (
    <>
      <div className="grid-overlay" />

      <div 
        className="bg-hero min-h-screen image-slide flex flex-col active w-screen p-6 md:p-12" 
        style={{ backgroundImage: `url('hero${currentImageIndex}.webp')`,}}
      >
        <div className="flex justify-between gap-6 flex-grow">
          <div className="flex flex-col justify-between md:justify-start items-start flex-grow w-full">
            <div className="flex justify-between items-center w-full">
              <img src="/navLogoHorizCircleWhite.png" alt="logo" className="h-10 md:h-20"/>

              <div className="flex md:hidden navbar ml-auto">
                <i className="fa-solid fa-bars"></i>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-center md:items-start justify-center md:mt-auto md:justify-end w-full pb-24 md:pb-0">
              <div className="flex-col flex items-center gap-4">
                <div className="places-remaining font-klein text-4xl md:text-5xl">{betaPlacesRemaining}</div>

                <div className="hero-cta-button relative text-black font-bold uppercase gap-6 text-xl">
                  <a className="flex items-center gap-4" href="#registration">
                    Join Alpha
                    <div className="arrow-button"><i className="fa-solid fa-chevrons-right text-xl"></i></div>
                  </a>
                </div>
              </div>
            </div>
            
            
            <h3  className="hidden md:block uppercase text-[#FFFFFF85] text-2xl hero-title font-extrabold font-klein">We keep you working hard</h3>
          </div>

          <div className="hidden md:flex flex-col justify-between items-end ml-auto">
            <div className="flex gap-4 items-start">
              <div className="hidden md:flex navbar ml-auto">
                <Link to="/app">Log in</Link>
              </div>
            </div>

            <h3 className="text-white text-right md:max-w-[175px] font-semibold hidden md:block">
            BFY is built for you - your very own AI yoga instructor. Generate yoga sessions which support your favourite sports, current training load and energy level.
            </h3>
          </div>
        </div>
      </div>

      <div id="registration" className="bg-white w-screen min-h-screen flex flex-col gap-6 md:gap-12 items-center justify-center p-6 md:p-12">

        <img src="/logoCircleTextRows.png" alt="logo" className="h-16 md:h-40" />

        <div className="border-2 border-[#cccccc] rounded-2xl p-4 md:p-12 grid md:grid-cols-2 gap-8 items-center container">
          <div>
            <h3 className="font-klein text-2xl md:text-5xl uppercase">Yoga for runners</h3>
            <p className="mt-4 md:mt-6">
              If you&apos;re a runner, cyclist, gym enthusiast or enjoy practically any other sport, use BFY to generate a yoga sequence that supports your favourite sport and even matches your current mood and energy level.
              <br /><br />
              Incorporating a regular yoga practice into your training program will likely yield significant benefits. It not only complements high-intensity sports but also addresses the specific needs of all athletes, including enhanced flexibility, strength, balance, and mental focus. By preventing injuries and promoting faster recovery, yoga will also help you continue to pursue your passions well into later life.
              <br /><br />
              Join our alpha programme and help shape our future. Thank you!
            </p>
          </div>

          <div className="flex justify-center">
            <div className="flex flex-col gap-4 w-full max-w-[400px]">
              <p className="text-sm text-muted text-center">Already a Member?&nbsp;<Link className="text-bfy-green font-extrabold" to="/app">Log In</Link></p>
              <button className="btn btn-social apple" onClick={() => loginWithSocial("apple")}>
                Sign up with Apple
              </button>
              {/*
              <button className="btn btn-social facebook" onClick={() => loginWithSocial("Facebook")}>
                Sign up with Facebook
              </button>
              */}
              <button className="btn btn-social google" onClick={() => loginWithSocial("google-oauth2")}>
                Sign up with Google
              </button>
              <button className="btn bg-bfy-green  py-3 px-3 rounded-md font-bold" onClick={() => loginWithRedirect()}>Sign up with Email</button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>

  );
}

export default Home;