import React from 'react';
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import Nav from './components/nav';

import Home from './pages';
import Dashboard from './pages/dashboard';
import ExchangeToken from './pages/exchangeToken';
import PracticePlayer from './pages/practice';

function App() {
  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/app", element: <><Nav /><Dashboard /></> },
    { path: "/practice", element: <><Nav /><PracticePlayer /></> },
    { path: "/exchangeToken", element: <ExchangeToken /> },
    { path: "*", element: <Navigate to="/404" replace /> }
  ]);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <RouterProvider router={router} />
    </Auth0Provider>
  );
}

export default App;