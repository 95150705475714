import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

export default function Nav() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <>
      <nav className="w-full fixed z-20 top-0 bg-black py-4 text-white border-b-8 border-[#c3d351]">
        <div className="container mx-auto px-4 flex items-center gap-6">
          <img src="/navLogo.png" alt="logo" className="h-8" />

          <div className="flex items-center gap-2 ml-auto">
            {isAuthenticated ? (
              <>
                <Link to="/app">Dashboard</Link>
                <button onClick={() => logout()}>Logout</button>
              </>
            ) : (
              <button onClick={() => loginWithRedirect()}>Login</button>
            )}
          </div>
        </div>
      </nav>
      <div style={{ height: "72px"}} />
    </>
  );
}