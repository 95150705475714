import React from 'react';

const Footer = () => {
  return (
    <div className="bg-black w-100 flex">
      <div className="container py-6 md:py-10 mx-auto flex flex-col gap-4 footer px-6 md:px-0">
        <div className="flex flex-col md:flex-row items-start md:justify-start gap-6 md:gap-4">
          <img className="h-16" src="/logoCircleWhite.png" alt="logo" />
          <div className="text-white">
            <p><strong>BFY LTD</strong>&nbsp;|&nbsp;Registered in England &amp; Wales: 15888842</p>
            <p><strong>Registered Office</strong>:&nbsp;46 Voyager House, London SW11 3GS</p>
            <p><strong>Email</strong>:&nbsp;<a href="mailto:info@bfy.ltd">info@bfy.ltd</a>&nbsp;<strong>Tel</strong>:&nbsp;03456&nbsp;808&nbsp;808</p>
          </div>

          <a className="md:ml-auto" href="/privacy.pdf" target="_blank">Privacy Policy</a>
        </div>

        <p className="text-white text-xs md:pt-16 pb-12">
          <strong>Legal Disclaimer</strong><br />
          The training recommendations provided on this website are offered in good faith and are derived from sources believed to be reliable and accurate at the time of release. These suggestions are intended solely as guidance and are not a substitute for professional medical advice. Before beginning any new exercise program, we strongly advise that you consult with a medical practitioner, especially if you have pre-existing health conditions, concerns about your physical fitness, or specific medical queries.
          <br /><br />
          BFY Ltd and its developers do not guarantee the accuracy, completeness, or timeliness of the information presented and are not responsible for any errors or omissions, whether caused by the negligence of us or our licensors.
          <br /><br />
          Furthermore, BFY Ltd and its developers are not liable for any personal injury, including death, caused by your use or misuse of the information provided. Your use of this website and reliance on any recommended practices or advice is solely at your own risk.
        </p>
      </div>
    </div>
  )
}

export default Footer;