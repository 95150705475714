import React, { useEffect, useState, useRef } from "react";
import { fetchPractice, generateAudio, saveActivity } from "../api";

interface Asana {
  asanaName: string;
  script: string;
  duration: string;
}

interface FlowSection {
  sectionOfFlow: string;
  duration: string;
  asanas: Asana[];
}

const PracticePlayer: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const practiceId = urlParams.get('pid');

  const [flow, setFlow] = useState<FlowSection[]>([]);
  const [asanasInFlow, setAsanasInFlow] = useState<Asana[]>([]);
  const [FetchingPractice, setFetchingPractice] = useState<boolean>(true);
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [nextAudioUrl, setNextAudioUrl] = useState<string>('');
  const [error, setError] = useState<string>('');
  const startTime = new Date().toISOString();
  const [currentAudioIndex, setCurrentAudioIndex] = useState<number>(0);
  const [fade, setFade] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const backgroundAudioRef = useRef<HTMLAudioElement | null>(null);
  const bellAudioRef = useRef<HTMLAudioElement | null>(null);

  const [endPracticeModal, setEndPracticeModal] = useState<boolean>(false);
  const [confirmedEndPractice, setConfirmedEndPractice] = useState<boolean>(false);

  const [menuScreen, setMenuScreen] = useState<boolean>(true);
  const [selectedBackgroundAudio, setSelectedBackgroundAudio] = useState<string>('/audios/background-music.mp3');
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState<string>('/practiceBgs/rain-ocean.gif');

  useEffect(() => {
    const attemptGeneratePractice = async (practiceId: string) => {
      setFetchingPractice(true);
      try {
        let practiceResponse = await fetchPractice(practiceId);
        let practice = practiceResponse.practice;
        let firstAsanaInFlow = practice[0].asanas[0];
        let initialAudio = await generateAudio(firstAsanaInFlow.script);

        setFlow(practice);
        setAsanasInFlow(practice.flatMap((flowSection: FlowSection) => flowSection.asanas.map((asana: Asana) => ({
          asanaName: asana.asanaName,
          script: asana.script,
          duration: asana.duration
        }))));
        setAudioUrl(initialAudio.response.audioUrl);
      } catch (error) {
        console.error("Error generating practice:", error);
        setError("There was an error generating the practice. Please try again.");
      }
      setFetchingPractice(false);
    };

    if (practiceId) {
      attemptGeneratePractice(practiceId);
    }
  }, [practiceId]);

  useEffect(() => {
    if (currentAudioIndex < asanasInFlow.length && !menuScreen) {
      const audioElement = document.getElementById('audio-player') as HTMLAudioElement | null;
      const bellAudioElement = bellAudioRef.current;
      if (!audioElement) return;

      const currentAsana = asanasInFlow[currentAudioIndex];
      const durationInMs = parseFloat(currentAsana.duration) * 60 * 1000;

      // Play the bell audio at the start of each new pose
      if (bellAudioElement) {
        bellAudioElement.play();
      }

      // Delay the script audio by 4 seconds after the bell audio
      setTimeout(() => {
        audioElement.play();

        // Set a timeout for the duration of the current asana
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          setFade(true);
          setTimeout(() => {
            setCurrentAudioIndex(prevIndex => prevIndex + 1);
            setAudioUrl(nextAudioUrl);
            setFade(false);
          }, 1000);
        }, durationInMs);

        // Progress bar update logic
        let startTime = Date.now();
        if (progressIntervalRef.current) {
          if (progressIntervalRef.current !== null) {
            clearInterval(progressIntervalRef.current);
          }
        }

        progressIntervalRef.current = setInterval(() => {
          if (!isPaused) {
            const elapsedTime = Date.now() - startTime;
            setProgress((elapsedTime / durationInMs) * 100);
            if (elapsedTime >= durationInMs) {
              if (progressIntervalRef.current !== null) {
                clearInterval(progressIntervalRef.current);
              }
            }
          }
        }, 100);
      }, 4000);

      // Fetch the next audio in advance
      const handlePlay = async () => {
        const nextIndex = currentAudioIndex + 1;
        if (nextIndex < asanasInFlow.length) {
          const nextAudio = await generateAudio(asanasInFlow[nextIndex].script);
          setNextAudioUrl(nextAudio.response.audioUrl);
        }
      };

      audioElement.addEventListener('play', handlePlay);
      return () => {
        audioElement.removeEventListener('play', handlePlay);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
      };
    }
  }, [currentAudioIndex, asanasInFlow, nextAudioUrl, isPaused, bellAudioRef, menuScreen]);

  const attemptEndPractice = async (withStrava: boolean) => {
    if (practiceId) {
      let endTime = new Date().toISOString();
      try {
        await saveActivity(practiceId, "google-oauth2|105402851918709444460", withStrava, startTime, endTime);
        window.location.href = '/app';
      } catch (error) {
        console.error('Error ending practice:', error);
      }
    }
  };

  useEffect(() => {
    if (backgroundAudioRef.current) {
      backgroundAudioRef.current.loop = true;
      backgroundAudioRef.current.play();
    }
  }, [selectedBackgroundAudio, backgroundAudioRef.current]);

  return (
    <>
      {endPracticeModal && (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-50">
          
          <div className="bg-white p-4 rounded-lg flex flex-col gap-4">
            {!confirmedEndPractice 
              ? <>
                <p>Are you sure you want to end the practice?</p>
                <button 
                  className="bg-red-500 text-white font-bold px-4 py-2 rounded-lg mt-6"
                  onClick={() => {
                    const audioElement = document.getElementById('audio-player') as HTMLAudioElement;
                    audioElement?.pause();
                    setConfirmedEndPractice(true);
                  }}
                >
                  End Practice
                </button>
                <button 
                  className="bg-gray-500 text-white font-bold px-4 py-2 rounded-lg"
                  onClick={() => setEndPracticeModal(false)}
                >
                  Cancel
                </button>
              </>
              : <>
                  <p>Would you like to share this practice on Strava?</p>

                  <button 
                    className="strava-button text-white font-bold px-4 py-2 rounded-lg mt-6"
                    onClick={() => attemptEndPractice(true)}
                  >
                    Yes
                  </button>

                  <button 
                    className="bg-gray-500 text-white font-bold px-4 py-2 rounded-lg"
                    onClick={() => attemptEndPractice(false)}
                  >
                    No
                  </button>
              </>
            }
          </div>
        </div>
      )}

      {FetchingPractice && (
        <div className="flex items-center justify-center w-screen h-screen pb-24">
          <p>Generating practice...</p>
        </div>
      )}

      {!FetchingPractice && (
        <div className="h-screen-nav max-w-screen flex">
          <div 
            className={`video-container ${fade ? 'fade-out' : 'fade-in'} h-full w-full bg-cover bg-center bg-no-repeat`}
            style={{ backgroundImage: `url('${selectedBackgroundImage}')` }}
          >
            {menuScreen
              ? <div className="menu-screen h-screen flex flex-col items-center justify-center gap-8">
                  <div className="bg-white p-8 rounded-lg flex flex-col gap-8 justify-center">
                    <h1 className="text-4xl font-bold">Customize Your Practice</h1>
                    <div className="flex flex-col items-start gap-4">
                      <label className="text-xl">
                        Select Background Audio:
                        <select
                          value={selectedBackgroundAudio}
                          onChange={(e) => setSelectedBackgroundAudio(e.target.value)}
                          className="ml-4 p-2 border rounded"
                        >
                          <option value="/audios/background-music.mp3">Sea Breeze</option>
                          <option value="/audios/background-music-2.mp3">Calm Mountains</option>
                        </select>
                      </label>

                      <label className="text-xl">
                        Select Background Image:
                        <select
                          value={selectedBackgroundImage}
                          onChange={(e) => setSelectedBackgroundImage(e.target.value)}
                          className="ml-4 p-2 border rounded"
                        >
                          <option value="/practiceBgs/rain-ocean.gif">Rain Ocean</option>
                          <option value="/practiceBgs/forest.gif">Forest</option>
                          <option value="/practiceBgs/mountains.gif">Mountains</option>
                        </select>
                      </label>
                    </div>
                    <button
                      className="bg-green-500 text-white font-bold px-6 py-3 rounded-lg mt-6"
                      onClick={() => setMenuScreen(false)}
                    >
                      Start Practice
                    </button>
                  </div>
                </div>
              : currentAudioIndex < asanasInFlow.length ? (
                  audioUrl && (
                    <>
                      <div className="h-full w-full flex flex-col md:items-center md:justify-center md:text-center gap-4 md:px-12">
                        <h2 className="text-white text-4xl font-semibold">{asanasInFlow[currentAudioIndex].asanaName}</h2>
                        <p className="text-white text-xl max-w-[900px]">{asanasInFlow[currentAudioIndex].script}</p>
                      </div>
                      <audio id="audio-player" src={audioUrl} />
                      <audio id="bell-audio" ref={bellAudioRef} src="/audios/bell.m4a" />

                      <div className="fixed bottom-8 left-0 right-0 w-full p-4 px-8 text-center">
                        <div className="bg-white rounded-full mr-[350px] flex items-center gap-4 px-4 md:px-8 py-2">
                          <button
                            className="font-bold"
                            onClick={() => {
                              const audioElement = document.getElementById('audio-player') as HTMLAudioElement;
                              audioElement.currentTime = 0;
                            }}
                          >
                            <i className="fa-solid fa-undo"></i>
                          </button>

                          <button
                            className="font-bold"
                            onClick={(event) => {
                              const audioElement = document.getElementById('audio-player') as HTMLAudioElement;
                              const iconElement = event.currentTarget.querySelector('i');

                              if (audioElement?.paused) {
                                audioElement?.play();
                                setIsPaused(false);
                                if (backgroundAudioRef.current) {
                                  backgroundAudioRef.current.play();
                                }
                                iconElement?.classList.remove('fa-play');
                                iconElement?.classList.add('fa-pause');
                              } else {
                                audioElement?.pause();
                                setIsPaused(true);
                                if (backgroundAudioRef.current) {
                                  backgroundAudioRef.current.pause();
                                }
                                iconElement?.classList.remove('fa-pause');
                                iconElement?.classList.add('fa-play');
                                if (timeoutRef.current) {
                                  clearTimeout(timeoutRef.current);
                                }
                              }
                            }}
                          >
                            <i className={`fa-solid ${(document.getElementById('audio-player') as HTMLAudioElement)?.paused ? 'fa-play' : 'fa-pause'}`}></i>
                          </button>

                          <button
                            className="font-bold"
                            onClick={() => {
                              const audioElement = document.getElementById('audio-player') as HTMLAudioElement;
                              audioElement?.pause();
                              setEndPracticeModal(true);
                            }}
                          >
                            <i className="fa-solid fa-stop"></i>
                          </button>

                          <div className="progress-bar-container w-full bg-gray-300 h-2 rounded-full overflow-hidden">
                            <div className="progress-bar bg-green-500 h-full " style={{ width: `${progress}%` }}></div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <div className="end-message">Yoga session completed!</div>
                )
            }
            <audio id="background-audio" ref={backgroundAudioRef} src={selectedBackgroundAudio} />
          </div>

          <div className="flex flex-col overflow-y-scroll h-auto border-l md:w-[350px]">
            {flow.map((flowSection, secIndex) => (
              <div key={secIndex}>
                <div className="w-full flex bg-gray-100 border-b p-2 items-center gap-4">
                  <h2 className="text-xl font-bold">{flowSection.sectionOfFlow}</h2>
                  <p>{flowSection.duration}</p>
                </div>
                {flowSection.asanas.map((asana, asanaIdx) => (
                  <div key={asanaIdx} className={`border-b border-gray-300 p-4 ${secIndex === currentAudioIndex && asanaIdx === currentAudioIndex ? "bg-yellow-100" : "bg-white"}`}>
                    <h3 className="text-lg"><strong className="font-bold">{asanaIdx + 1}. {asana.asanaName}</strong> ({asana.duration})</h3>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default PracticePlayer;