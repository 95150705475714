import { UserProfile } from './types';
const BASE_URL = process.env.REACT_APP_API_URL;

const fetchFromApi = async (apiPath: string, bodyData: {}) => {
  const response = await fetch(`${BASE_URL}${apiPath}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  if (!response.ok) throw new Error('Network response was not ok');

  return await response.json();
}

export const generatePractice = async (userProfile: UserProfile) => {
  try {
    const response = await fetchFromApi('chat/generate-practice', { userProfile });
    return response;
  } catch (error) {
    console.error('There was a problem fetching accounts:', error);
    throw error;
  }
};

export const generateAudio = async (text: string) => {
  try {
    const response = await fetchFromApi('chat/generate-audio', { text });
    return response;
  } catch (error) {
    console.error('There was a problem fetching accounts:', error);
    throw error;
  }
}

export const getUserProfile = async (user: any) => {
  try {
    const response = await fetchFromApi('users/get-user-profile', { user });
    return response;
  } catch (error) {
    console.error('There was a problem fetching accounts:', error);
    throw error;
  }
}

export const updateUserProfile = async (subId: string, userDetails: UserProfile) => {
  try {
    const response = await fetchFromApi('users/update-user-profile', { subId, userDetails });
    return response;
  } catch (error) {
    console.error('There was a problem fetching accounts:', error);
    throw error;
  }
}

export const getNumberOfUsers = async () => {
  try {
    const response = await fetchFromApi('users/count', {});
    return response;
  } catch (error) {
    console.error('There was a problem fetching accounts:', error);
    throw error;
  }
}

export const storeStravaTokensForUser = async (code:string, subId: string) => {
  try {
    const response = await fetchFromApi('users/store-access-tokens', { code, subId });
    return response;
  } catch (error) {
    console.error('There was a problem fetching accounts:', error);
    throw error;
  }
}

export const createPractice = async (practice: any, subId: string) => {
  try {
    const response = await fetchFromApi('practices/create-practice', { practice, subId });
    return response;
  } catch (error) {
    console.error('There was a problem fetching accounts:', error);
    throw error;
  }
}

export const fetchPractice = async (practiceId: string) => {
  try {
    const response = await fetchFromApi('practices/fetch', { practiceId });
    return response;
  } catch (error) {
    console.error('There was a problem fetching accounts:', error);
    throw error;
  }
}

export const saveActivity = async (practiceId: string, subId: string, withStrava: boolean, startTime: string, endTime: string) => {
  try {
    const response = await fetchFromApi('activities/save', { practiceId, subId, withStrava, startTime, endTime });
    return response;
  } catch (error) {
    console.error('There was a problem fetching accounts:', error);
    throw error;
  }
}