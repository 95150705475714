import { useAuth0 } from '@auth0/auth0-react';

export const useUserOrRedirectToLogin = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');


  // If authenticated, return the user's sub (unique identifier)
  if (isAuthenticated && user) {
    return user.sub;
  } 

  const redirect = window.location.pathname + window.location.search;
  loginWithRedirect({
    appState: { returnTo: redirect },
  });
}