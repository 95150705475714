import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { storeStravaTokensForUser } from '../api';

const ExchangeToken = () => {

  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  console.log(code);
  useEffect(() => {
    if ((!isLoading && !isAuthenticated) || (!isLoading && !user)) {
      // Save the current location before redirecting
      const redirect = window.location.pathname + window.location.search;
      loginWithRedirect({
        appState: { returnTo: redirect },
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    const updateTokenAndRedirect = async (code: string, sub: string) => {
      console.log('subId:', sub);
      try {
        await storeStravaTokensForUser(code, sub);
        window.location.href = '/app';
      } catch (error) {
        console.error('Error updating token:', error);
      }
    }

    code && user && user.sub && updateTokenAndRedirect(code, user.sub);
    console.log(user, isAuthenticated, isLoading);
  }, [code, user]);

  return (
    <div className="container">
      <h1>Exchange Tokens</h1>
    </div>
  );
}

export default ExchangeToken;